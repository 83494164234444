/*!
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

.fullscreen-modal {
    .MuiDialogContent-root {
        display: flex;
        flex-direction: column;
        overflow: hidden;
    }

    &__header {
        display: flex;
        position: relative;
        z-index: $menu-z-index;
        justify-content: center;
        align-items: center;
        padding: 20px;
        font-size: 16px;
        color: $color-primary;
        background-color: $color-secondary;

        &-action {
            position: absolute;
            left: 10px;
            font-size: 40px !important;
            cursor: pointer;
        }

        span {
            position: absolute;
            right: 50%;
            transform: translateX(50%);
        }
    }

    .MuiPaper-root {
        display: flex;
        justify-content: center;
        width: 100%;
        border-radius: $small-gap;

        @include screen(mobile) {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            max-height: 100vh;
            margin: 0;
            font-size: 12px;
            border-radius: 0;
        }

        .MuiDialogContent-root {
            padding: 0;
            overflow: auto;
        }
    }
}

.confirmation-dialog {
    .MuiPaper-root {
        display: flex;
        justify-content: center;
        width: 100%;
        max-width: 300px;
        border-radius: $small-gap;
    }

    &__content {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 25px;
        text-align: center;
    }

    &__image-container {
        display: flex;
        justify-content: center;
        width: 50px;
        height: 50px;
        background-color: $color-red;
        border-radius: 10px;

        img {
            width: 70%;
        }
    }

    &__title {
        margin: 10px 0;
        font-size: 18px;
        color: $color-red;
        font-weight: bold;
    }

    &__text {
        margin-bottom: 10px;
        font-size: 14px;
    }

    &__action-button {
        background-color: $color-red !important;
        margin: $small-gap 0 !important;
    }

    &__cancel-button {
        background-color: #ffffff !important;
        color: $color-red !important;
        box-shadow: none !important;
    }

    .MuiButton-root {
        width: 100%;
    }

    &__header {
        display: flex;
        position: relative;
        z-index: $menu-z-index;
        justify-content: center;
        align-items: center;
        padding: 20px;
        font-size: 16px;
        color: $color-primary;
        background-color: $color-secondary;

        svg {
            position: absolute;
            left: 10px;
            font-size: 40px;
            cursor: pointer;
        }

        span {
            position: absolute;
            right: 50%;
            transform: translateX(50%);
        }
    }

    .MuiDialogContent-root {
        display: flex;
        flex-direction: column;
        padding: 0 !important;
    }
}
