/*!
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

.root {
    height: 100vh;

    @include screen(mobile) {
        background-color: $color-secondary;
    }

    .app {
        height: 100vh;

        .app-router {
            display: flex;
            height: 100vh;

            .app-container {
                display: flex;
                flex-direction: column;
                width: 100%;
                height: 100vh;
                overflow: hidden;

                @include screen(mobile) {
                    position: fixed;
                    height: 100%;
                }
            }
        }
    }
}

.table-container {
    width: 100%;
}
