/*!
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

// BASE
@import 'base/mixins';
@import 'base/fonts';
@import 'base/variables';
@import 'base/base';
@import 'base/layout';

// VIEWS
@import 'components/views/drawer';
@import 'components/views/navbar';
@import 'components/views/bottomBar';
@import 'components/views/form';
@import 'components/views/header';
@import 'components/views/bookingForm';

// SCREENS
@import 'components/screens/accept-invite';
@import 'components/screens/auth';
@import 'components/screens/bookings';
@import 'components/screens/landing';
@import 'components/screens/map';
@import 'components/screens/profile';
@import 'components/screens/qrcode';
@import 'components/screens/reservation';

// ELEMENTS
@import 'components/elements/buttons';
@import 'components/elements/calendar';
@import 'components/elements/dialog';
@import 'components/elements/language-select';
@import 'components/elements/loader';
@import 'components/elements/modal';
@import 'components/elements/notification';
@import 'components/elements/payment_method';
@import 'components/elements/reservation_group';
@import 'components/elements/reservation_map_counter';
