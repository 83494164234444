/*!
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

.navbar {
    display: none;
    position: sticky;
    z-index: $nav-z-index;
    top: 0;
    grid-gap: $small-gap;
    flex-shrink: 0;
    width: 100vw;
    height: 58px;
    background-color: $color-secondary;

    @include screen(mobile) {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
    }

    img {
        max-height: 30px;
        margin-left: $small-gap;
    }

    svg {
        font-size: 18px;
        color: $color-secondary;
    }

    span {
        width: 100%;
        font-size: 16px;
        text-align: center;
        color: $color-primary;
    }

    .MuiFormControl-root {
        .MuiInputBase-root {
            background-color: #ffffff;
            border-radius: 20px;

            input {
                font-size: 13px;
                color: $color-dark-grey;
            }
        }
    }
}
