/*!
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

.narrow-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 400px;
    height: 100%;

    @include screen(mobile) {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        max-width: 400px;
        justify-content: flex-start;
        height: 100%;
        max-height: 200px;
    }

    .login-inputs {
        justify-content: center;
    }

    .login-bottom {
        margin: 20px 0;
        text-align: center;

        a {
            font-size: 11px;
            color: #ffffff;
        }
    }
}

.wide-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 800px;

    &__grid-container {
        display: grid;
        grid-column-gap: $small-gap;
        grid-template-columns: repeat(2, 1fr);
        align-items: center;
        width: 100%;

        .MuiFormControl-root {
            justify-content: flex-end;
        }

        &__double-space {
            grid-column-end: span 2;

            @include screen(mobile) {
                width: 100%;
            }
        }

        &__double-space-mobile {
            @include screen(mobile) {
                grid-column-end: span 2;
                width: 100%;
            }
        }

        &__section-tooltip {
            margin-top: 10px;
            font-size: 14px;
            opacity: 0.7;
        }

        &__double-input {
            display: grid;
            position: relative;
            grid-template-columns: auto auto 1fr;
            width: 100%;
            margin-bottom: 10px;

            .MuiFormControl-root {
                margin: 0 !important;

                &:first-of-type {
                    justify-content: flex-start;

                    .MuiInputBase-root {
                        padding-bottom: 3px;
                        border-top-right-radius: 0 !important;
                        border-bottom-right-radius: 0 !important;

                        .MuiSelect-select {
                            font-size: 14px;
                            color: #ffffff;
                        }
                    }
                }

                &:last-of-type {
                    .MuiInputBase-root {
                        border-top-left-radius: 0 !important;
                        border-bottom-left-radius: 0 !important;
                    }
                }
            }

            .Mui-error {
                padding-bottom: 4px !important;
            }

            &__divider {
                width: 1px;
                height: 40px;
                background-color: rgba(#ffffff, 0.4);

                div {
                    position: relative;
                    top: 50%;
                    height: 28px;
                    background-color: rgba(#ffffff, 0.8);
                    transform: translateY(-50%);
                }
            }
        }

        &__terms-container {
            display: grid;
            grid-template-columns: auto 1fr;
            align-items: center;
            width: 100%;
            font-size: 11px;

            .MuiFormControl-root {
                margin: 0 !important;
            }

            &__terms-link {
                color: #ffffff;
                cursor: pointer;
                text-decoration: none;
            }

            &--margin-top {
                margin-top: $small-gap;
            }
        }
    }

    &__wider-grid-container {
        display: grid;
        grid-column-gap: $small-gap;
        grid-template-columns: repeat(4, 1fr) auto;
        align-items: center;
        width: 100%;

        @include screen(mobile) {
            display: flex;
            flex-direction: column;

            svg {
                align-self: flex-end;
            }

            .MuiFormControl-root {
                width: 100% !important;
            }
        }

        &__separator {
            grid-column-end: span 5;
            width: 100%;
            height: 1px;
            margin: $small-gap 0;
            background-color: $color-light-grey;
        }

        .MuiFormControl-marginNormal {
            bottom: 5px;
        }

        svg {
            &:hover {
                color: red;
                cursor: pointer;
            }
        }
    }

    .add-button {
        @include screen(mobile) {
            align-self: center !important;
        }
    }

    &__checkbox-container {
        display: grid;
        grid-column-gap: $small-gap;
        grid-template-columns: repeat(2, auto);
        align-self: start;
    }

    &__area-picture-container {
        display: flex;
        align-items: center;
        width: 100%;

        @include screen(mobile) {
            flex-direction: column;
        }

        img {
            height: 150px;
            margin-right: $small-gap;

            @include screen(mobile) {
                margin-right: 0;
                margin-bottom: $small-gap;
            }
        }

        p {
            margin-bottom: 0;
            color: $color-dark-grey;
        }
    }
}

.widest-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100%;

    &__grid-container {
        display: grid;
        grid-column-gap: $small-gap;
        grid-template-columns: repeat(4, 1fr);
        align-items: center;
        width: 100%;
        margin-bottom: $small-gap;

        .MuiFormControl-marginNormal {
            bottom: 5px;
        }
    }
}

.section-header {
    display: flex;
    grid-column-end: span 2;
    align-items: center;
    align-self: flex-start;
    font-weight: 600;
    color: $color-blue;

    &:not(:first-child) {
        margin-top: $small-gap;
    }

    svg {
        margin-right: 10px;
    }

    &--margin {
        margin-bottom: $small-gap;
    }
}

.custom-material-input {
    position: relative;
    width: 100%;
    margin-top: $small-gap;
    margin-bottom: 5px;

    * {
        width: 100%;
    }

    input {
        &::placeholder {
            color: transparent;
        }
    }

    label {
        position: absolute;
        top: 10px;
        font-size: 16px;
        font-weight: normal;
        color: rgba(0, 0, 0, 0.54);
        pointer-events: none;
        transition: 0.2s ease all;
        -moz-transition: 0.2s ease all;
        -webkit-transition: 0.2s ease all;
    }

    .google-autocomplete {
        display: block;
        padding: 10px 10px 5px 0;
        font-size: 16px;
        border: none;
        border-bottom: 1px solid rgba(0, 0, 0, 0.54);

        &:focus {
            outline: none;
        }

        &:focus ~ label,
        &:not(:placeholder-shown) ~ label {
            top: -10px;
            font-size: 12px;
        }

        &:focus ~ label {
            color: #3f51b5;
        }

        &:focus ~ .bar {
            &::before,
            &::after {
                width: 50%;
            }
        }
    }

    .bar {
        display: block;
        position: relative;
        bottom: -1px;

        &::before,
        &::after {
            content: '';
            position: absolute;
            bottom: 1px;
            width: 0;
            height: 2px;
            background: #3f51b5;
            transition: 0.2s ease all;
            -moz-transition: 0.2s ease all;
            -webkit-transition: 0.2s ease all;
        }

        &::before {
            left: 50%;
        }

        &::after {
            right: 50%;
        }
    }

    &--errors {
        label {
            color: red !important;
        }

        .google-autocomplete {
            border-bottom: 2px solid red;
        }

        .bar {
            &::before,
            &::after {
                background: red !important;
            }
        }
    }
}

.margin-button {
    margin: $small-gap !important;
}

.material-autocomplete {
    position: relative !important;
    bottom: 5px !important;

    .MuiFormControl-root {
        margin-top: 5px !important;
        margin-bottom: 8px !important;

        .MuiInputBase-root {
            padding: 5px 10px !important;

            &::before {
                display: none !important;
            }

            input {
                margin-left: 10px;
            }
        }
    }

    @include screen(mobile) {
        width: 100% !important;
    }
}

.form-tooltip {
    align-self: start;
    margin-left: 30px;
    opacity: 0.7;
}

.form-section-title {
    align-self: start;
    margin: $small-gap 0;
    font-weight: 600;
}

.add-schedule-container {
    width: 100%;

    @include screen(mobile) {
        text-align: center;
    }
}

.file-input-wrapper {
    position: relative;

    &__input {
        position: absolute;
        opacity: 0;
        width: 0;
    }

    &__button {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 8px 12px;
        font-size: 12px;
        font-weight: 700;
        text-transform: uppercase;
        color: $color-secondary;
        background-color: $color-primary;
        border-radius: 4px;
        cursor: pointer;

        svg {
            margin-right: 10px;
        }
    }

    p {
        margin: 0 0 10px 0;
        text-align: center;
    }

    &__picture-container {
        position: relative;
        text-align: center;
        cursor: pointer;

        &__picture-overlay {
            position: absolute;
            top: 0;
            right: 0;
            left: 0;
            bottom: 0;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 20px;
            color: $color-primary;
            background-color: $color-secondary;
            border-radius: 50%;
            opacity: 0;
            transition: opacity 0.3s linear !important;

            img {
                width: 80%;
            }
        }

        &:hover {
            .profile-picture {
                opacity: 0 !important;
            }

            .file-input-wrapper__picture-container__picture-overlay {
                opacity: 1;
            }
        }
    }
}

.MuiFormControl-root {
    .MuiInputBase-root {
        padding: 5px 20px;
        background-color: rgba(#ffffff, 0.4);
        border-radius: 10px;

        .MuiInputAdornment-positionStart {
            margin-left: -10px;
        }

        .MuiInputAdornment-positionEnd {
            margin-right: -15px;
        }

        svg {
            right: 10px;
        }

        .MuiAutocomplete-popupIndicator {
            margin-right: 15px;
        }

        input {
            font-size: 14px;
            color: #ffffff;

            &::placeholder,
            &::-moz-placeholder,
            &::-webkit-input-placeholder {
                opacity: 1;
            }
        }

        &::before {
            border: none;
        }

        &::after {
            border: none;
        }
    }

    .MuiFormLabel-root.Mui-focused {
        color: rgba(0, 0, 0, 0.54);
    }
}
