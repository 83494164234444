/*!
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

.reservation-dialog {
    &__map-header {
        width: 100%;
        margin-bottom: 10px;
        font-size: 1rem;

        &__title {
            color: $color-primary;
        }

        &__capacity {
            margin: 5px 0 10px 0;
            font-size: 11px;
            color: $color-dark-grey;
        }
    }

    &__business-container {
        display: flex;
        justify-content: space-between;
        width: 100%;

        &__header {
            display: flex;
            align-items: center;
            min-height: 40px;
            width: 100%;
            background-color: $color-primary;
            color: white;
            padding-left: 26px;
            font-family: $font-primary;

            &--error {
                background-color: $color-red;
            }

            &__payment-badger {
                border-radius: 50%;
                width: 28px;
                height: 28px;
                background-color: white;
                margin: 0 6px 0 4px;

                > img {
                    width: 22px;
                    margin: 3px 0 0 3px;

                    &.custom-image {
                        margin: 7px 0 0 5px;
                        width: 18px;
                    }
                }
            }
        }

        &__business {
            display: flex;
            flex-direction: column;
            width: 100%;

            &__top {
                display: flex;
                justify-content: space-between;

                &__name {
                    font-size: 1.25rem;
                    color: $color-primary;
                    margin-bottom: 10px;
                    font-family: $font-open-sans-bold;

                    &--yellow {
                        font-size: 1.25rem;
                        margin-bottom: 10px;
                        font-family: $font-open-sans-bold;
                        color: $color-secondary;
                    }
                }
            }

            &__location {
                display: flex;
                align-items: center;
                font-size: .75rem;
                margin-bottom: 4px;

                svg {
                    margin-right: 8px;
                    transform: scale(1.2);
                    color: $color-secondary;
                }

                img {
                    margin-left: 2px;
                    width: 23px;
                    height: 23px;
                    margin-right: 7px;
                }
            }

            &__footer {
                display: flex;
                margin: 10px 0;
                justify-content: space-between;

                &__location {
                    display: flex;
                    align-items: center;
                    font-size: .75rem;

                    svg {
                        margin-right: 8px;
                        color: $color-secondary;
                    }
                }
            }

        }
    }

    &__price-container {
        display: flex;
        flex-flow: column;
        width: 100%;
        box-sizing: content-box;
        justify-content: space-between;
        margin-bottom: 20px;

        &__price {
            hr {
                margin: 15px 0;
                margin-left: 7vw;
                border: none;
                border-bottom: 1px dashed $color-secondary;
                max-width: calc(100% - 14vw);
            }

            &__next-step {
                display: flex;
                justify-content: space-between;
                padding: 6px 7vw 0 7vw;
            }
        }

        &__message {
            margin-top: 25px;
            padding: 0 7vw;
        }
    }

    &__payment-details {
        width: 100%;

        &__label {
            text-transform: uppercase;
            font-size: .75rem;
            font-family: $font-open-sans-light;

            &--center-vertical {
                padding-top: 63px;
            }

            &--below-another {
                padding-top: 26px;
            }
        }

        &__loader {
            padding: 4vh 0;
            text-align: center;

            .MuiCircularProgress-colorPrimary {
                color: $color-primary;
            }
        }

        &__value {
            text-transform: uppercase;
            font-size: 1.25rem;
            color: $color-primary;
            font-family: $font-open-sans-bold;
        }
    }

    &__mbway-timer {
        width: 100%;
        padding: 30px 10px 50px 10px;
        display: flex;
        align-items: center;
        flex-direction: column;
        color: $color-dark-grey;

        > .MuiSvgIcon-root {
            fill: $color-secondary;
            margin-bottom: 20px;
        }

        &__timer {
            font-size: 50px;
            line-height: 52px;
            color: $color-primary;
            margin: 10px 0;
        }
    }

    &__success {
        display: flex;
        flex-flow: column;
        padding: 20px 34px;

        &__title {
            font-family: $font-open-sans-bold;
            color: $color-primary;
            font-size: 1.6rem;
            line-height: 29px;
        }

        &__message {
            padding: 6px 0 14px 0;
            font-family: $font-primary;
            font-size: .75rem;
        }

        &__image {
            padding: 0 26px;
        }
    }

    &__error {
        @extend .reservation-dialog__success;

        &__title {
            @extend .reservation-dialog__success__title;
            color: $color-red;
        }

        &__message {
            @extend .reservation-dialog__success__message;
        }

        &__image {
            @extend .reservation-dialog__success__image;
            opacity: .26;
        }
    }

    &__big-area-description {
        padding: 0 20px 40px 20px;
        white-space: break-spaces;
        font-size: 14px;
    }

    &__spacing {
        padding: 0 20px;

        &--login {
            @extend .reservation-dialog__spacing;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            background-color: $color-primary;
            height: 100%;

            svg {
                color: $color-secondary;
            }

            @include screen(mobile) {
                justify-content: flex-start;
            }

            a {
                font-size: 14px !important;
            }

            h3 {
                font-size: 1.25rem;
                font-family: $font-open-sans-bold;
                color: $color-secondary;
                margin-bottom: 0;
            }

            button:last-child {
                color: $color-secondary;
                font-weight: bold;
                text-decoration: underline;
                text-underline-offset: 5px;
                padding-top: 5px;

                &:hover {
                    background-color: transparent;
                    text-decoration: underline;
                }
            }
        }

        &--margin-top {
            padding: 15px 20px;
        }

        &__area-description {
            padding: 20px 0;
            font-size: 14px;
            white-space: break-spaces;

            p {
                margin: 0;
            }

            button {
                all: unset;
                margin-top: 8px;
                text-decoration: underline;
                cursor: pointer;
            }
        }

        &__photos-grid-container {
            display: grid;
            grid-gap: $small-gap;
            grid-template-columns: repeat(4, 1fr);
            width: 100%;
            margin-top: 15px;

            @include screen(mobile) {
                grid-template-columns: repeat(3, 1fr);
            }

            &__photo-container {
                position: relative;
                width: 100%;
                padding-top: 100%;
                background-size: cover !important;
                border-radius: 10px;
                box-shadow: rgba(0, 0, 0, 0.24) 0 3px 8px;
                cursor: pointer;

                &--selected {
                    color: $color-primary;

                    .reservation-dialog__photos-grid-container__photo-container__text-container {
                        border: 1px solid $color-primary;
                    }
                }

                &__text-container {
                    display: flex;
                    position: absolute;
                    bottom: 0;
                    width: 100%;
                    height: 25px;
                    padding: 0 3px;
                    overflow: hidden;
                    font-size: 9px;
                    background-color: $color-secondary;
                    border-radius: 10px;

                    @include screen(mobile) {
                        height: unset;
                        padding: 5px;
                        font-size: .7rem;
                    }

                    span {
                        margin: auto;
                        text-align: center;
                    }
                }
            }
        }
    }

    &__footer {
        display: grid !important;
        justify-content: unset !important;
        place-items: center;
        width: 100%;
        min-height: 84px;
        background-color: $color-secondary;
        box-shadow: 0 -4px 32px rgba(black, .3);

        &__button {
            padding: 0 !important;
            display: flex;
            justify-content: space-between;
            background-color: $color-primary !important;
            border-radius: 27px !important;

            &.MuiButton-root {
                padding: 0 !important;
                padding-left: 30px !important;
                padding-right: 3px !important;
            }

            .MuiButton-label {
                padding: 0 !important;
            }

            &__text {
                color: white;
                font-family: $font-open-sans-bold;

                &--secondary {
                    color: $color-secondary;
                }
            }

            &.disabled {
                background-color: $color-medium-grey !important;

                span {
                    color: #ffffff !important;
                }
            }
        }
    }

    .wide-form {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        max-width: 800px;
        padding: 15px 38px;

        &__grid-container {
            width: 100%;
            display: grid;
            grid-row-gap: $small-gap;
            grid-template-columns: 1fr;
            align-items: center;
            margin-top: 20px;
            margin-bottom: 10px;

            .MuiFormControl-root {
                justify-content: flex-end;
            }

            &__row {
                width: 100%;
                display: grid;
                grid-template-columns: 1fr 1fr;
                gap: $small-gap;

                @include screen(mobile) {
                    grid-template-columns: 1fr;
                }

                &__input-container {
                    &__label {
                        display: flex;
                        align-items: center;
                        margin-bottom: 5px;
                        font-size: 12px;

                        svg {
                            color: $color-secondary;
                        }

                        span {
                            margin-left: 10px;
                            color: $color-primary;
                        }

                        &--footer-description {
                            align-items: flex-start;

                            span {
                                color: $color-medium-grey;
                            }
                        }
                    }

                    .MuiFormControl-root {
                        width: 100%;
                        margin: 0;

                        .MuiInputBase-root {
                            height: 45px;
                            box-shadow: rgba(0, 0, 0, 0.1) 0 1px 3px 0, rgba(0, 0, 0, 0.06) 0 1px 2px 0;

                            input {
                                font-size: 14px;
                                font-weight: 300;
                                color: #000000;

                                &::placeholder,
                                &::-moz-placeholder,
                                &::-webkit-input-placeholder {
                                    opacity: 1;
                                }
                            }

                            &::before {
                                border: none;
                            }

                            &::after {
                                border: none;
                            }
                        }

                        .Mui-disabled {
                            input {
                                opacity: 0.5;
                            }

                            .MuiSelect-root {
                                opacity: 0.5;
                            }

                            .MuiSelect-nativeInput {
                                display: none;
                            }
                        }
                    }

                    &--multiline {
                        @extend .wide-form__grid-container__row__input-container;

                        .black-text {
                            color: $color-black;
                        }

                        .MuiFormControl-root {
                            width: 100%;
                            margin: 0;

                            .MuiInputBase-root {
                                height: 100%;
                                padding: 10px;
                            }
                        }
                    }
                }
            }
        }
    }

    .no-periods-available {
        display: flex;
        margin-bottom: 5px;
        color: $color-red;
    }
}
