/*!
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

.qrcode-screen {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    background-color: #ffffff;

    &__reader {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;

        section {
            display: flex;
            position: inherit;
            justify-content: center;
            align-items: center;
            height: 100%;
            padding-top: 0 !important;

            div {
                position: inherit !important;
                max-width: 283px;
                max-height: 283px;
                border: 7px dashed #3ea08e !important;
                border-radius: 25px;
                box-shadow: none !important;
            }
        }
    }

    &__icon {
        font-size: 80px !important;
        color: $color-red;
    }

    &__repeat-button {
        margin-top: $small-gap !important;
        color: #ffffff;
        background-color: $color-secondary !important;
    }

    &__reserve-button {
        margin-top: $small-gap !important;
        color: #ffffff;
        background-color: $color-primary !important;
    }

    &__cancel-button {
        margin-top: $small-gap !important;
        color: $color-primary !important;
        background-color: #ffffff !important;
    }
}
