/*!
 *
 * @Copyright 2021 VOID SOFTWARE, S.A.
 *
 */

.language-overlay {
    position: absolute;
    bottom: $small-gap;
    right: 30px;
    width: 100%;
    max-width: 150px;
    z-index: $backdrop-z-index;

    @include screen(mobile) {
        display: none;
    }

    &--mobile {
        display: none;
        position: fixed;
        max-width: 90px;

        @include screen(mobile) {
            display: block;
        }
    }
}

.language-select {
    .MuiInputBase-root {
        background-color: #FFFFFF;
    }

    &--mobile {
        align-self: flex-end;
        margin-right: 20px;
        margin-top: 20px;
        margin-bottom: -75px;
        display: none;

        @include screen(mobile) {
            display: block;
        }
    }

    &--always {
        display: block;
        margin: 0;

        @include screen(mobile) {
            display: block;
        }
    }
}
