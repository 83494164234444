/*!
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

.auth-screen {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    min-height: 100%;
    overflow: auto;
    background: linear-gradient($color-primary, $color-primary, $color-secondary);
    background-attachment: fixed;
    scroll-behavior: smooth;

    &__login-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        padding: $small-gap;
    }

    &__recover-container,
    &__reset-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        height: 100%;
        padding: $small-gap;
        background-color: $color-primary;

        .recover-button {
            margin-top: 15px;
        }

        &__bottom {
            padding-bottom: 10px;
            font-size: 11px;
            color: #ffffff;
        }

        .auth-screen__greeting {
            &__title {
                font-size: 22px;
            }

            &__text {
                margin: 0 50px;
                font-size: 14px;
                text-align: center;
            }
        }
    }

    &__register-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        margin-top: -40px;
        padding: $small-gap;
        padding-top: 40px;
        background-color: $color-primary;

        .auth-screen__greeting {
            &__title {
                font-size: 22px;
            }
        }
    }

    &__activate-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        padding: $small-gap;
        font-size: 12px;
        background-color: $color-secondary;

        input {
            color: $color-primary !important;
        }

        .activate-buttons {
            text-align: center;

            .hidden-button {
                background: transparent;
                border: none;
                cursor: pointer;
            }

            img {
                cursor: pointer;
            }
        }
    }

    &__logo {
        margin: 50px 0 40px 0;
    }

    &__new-account {
        z-index: $above-normal-z-index;
        margin: 50px 0 20px 0;
    }

    .register-bottom {
        margin: 5px 0 20px 0;
    }

    .MuiFormControl-root {
        margin-bottom: 10px;

        svg {
            color: $color-secondary;
        }
    }

    &__greeting {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 40px;

        &__text {
            font-size: 12px;
            color: #ffffff;
        }

        &__title {
            font-size: 25px;
            color: $color-secondary;
        }
    }
}
