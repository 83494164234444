/*!
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

@font-face {
    font-family: 'PaytoneOne-Regular';
    font-style: normal;
    font-weight: normal;
    src: url('../../fonts/PaytoneOne-Regular.eot?#iefix') format('embedded-opentype'),
        url('../../fonts/PaytoneOne-Regular.woff') format('woff'),
        url('../../fonts/PaytoneOne-Regular.ttf') format('truetype'),
        url('../../fonts/PaytoneOne-Regular.svg#PaytoneOne-Regular') format('svg');
}

@font-face {
    font-family: 'OpenSansRegular';
    font-style: normal;
    font-weight: normal;
    src: url('../../fonts/open_sans_regular-webfont.woff2') format('woff2'),
        url('../../fonts/open_sans_regular-webfont.woff') format('woff');
}

@font-face {
    font-family: 'OpenSansLight';
    font-style: normal;
    font-weight: normal;
    src: url('../../fonts/open_sans_light-webfont.woff2') format('woff2'),
        url('../../fonts/open_sans_light-webfont.woff') format('woff');
}

@font-face {
    font-family: 'OpenSansSemibold';
    font-style: normal;
    font-weight: normal;
    src: url('../../fonts/open_sans_semibold-webfont.woff2') format('woff2'),
        url('../../fonts/open_sans_semibold-webfont.woff') format('woff');
}

@font-face {
    font-family: 'OpenSansBold';
    font-style: normal;
    font-weight: normal;
    src: url('../../fonts/open_sans_bold-webfont.woff2') format('woff2'),
        url('../../fonts/open_sans_bold-webfont.woff') format('woff');
}
