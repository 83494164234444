/**
 *
 * @Copyright 2022 VOID SOFTWARE, S.A.
 *
 */

.accept-invite-screen {
    width: 100%;
    height: 100%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    background-color: $color-primary;

    &__inner {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        max-width: 420px;

        p {
            color: $color-light-grey;
            font-size: 18px;
            margin: 0 0 40px 0;
        }

        h2 {
            font-size: 22px;
            color: $color-secondary;
            font-family: $font-open-sans-bold;
            margin: 10px 0;
        }

        h3 {
            font-size: 18px;
            font-family: $font-open-sans-bold;
            color: #FFF;
            margin: 0 0 30px 0;
            text-align: center;
        }

        &__logo {
            height: 85px;
        }

        &__error-msg {
            padding: 15px;
            border-radius: 8px;
            background-color: rgba(#FFF, 1);

            h4 {
                font-size: 16px;
                font-family: $font-open-sans-semibold;
                margin: 0;
                color: $color-red-FF0909;
            }
        }

        &__msg {
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-top: 40px;
            text-align: center;

            a {
                all: unset;
                padding: 15px 20px;
                background-color: #FFF;
                border-radius: 4px;
                font-weight: 500;
                color: $color-primary;
                box-shadow: 0 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
                cursor: pointer;
            }
        }
    }
}
