/*!
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

.bottom-bar {
    z-index: $nav-z-index;
    background-color: $color-secondary;

    .MuiTabs-indicator {
        top: 0;
        height: 3px;
        background-color: $color-primary;
        border-radius: 2px;
    }

    a {
        padding: $small-gap;

        img {
            position: relative;
            left: 50%;
            transform: translateX(-50%);
        }
    }

    .MuiTabs-flexContainer {
        justify-content: center;

        .MuiTab-fullWidth {
            min-width: 0;
            max-width: 120px;

            @include screen(mobile) {
                max-width: 100%;
            }
        }
    }
}
