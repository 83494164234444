/*!
 *
 * @Copyright 2021 VOID SOFTWARE, S.A.
 *
 */

.multiple-date-picker {
    display: inline-flex;
    height: 440px;

    @include screen(tablet) {
        height: 80vh;
        flex-flow: column;
        justify-content: space-between;
    }

    &__right-side {
        min-width: 250px;
        display: flex;
        flex-direction: column;
        background-color: rgba(0, 0, 0, .03);

        @include screen(tablet) {
            overflow: scroll;
        }

        &__header {
            display: flex;
            align-items: center;
            align-content: center;
            justify-content: space-between;
            padding: 20px 32px 0 16px;
            
            h6 {
                font-size: 1.3rem;
            }
        }

        &__list {
            flex: 1;
            overflow-y: auto;
        }
    }

    &__actions {
        display: flex;

        @include screen(desktop) {
            display: none !important;
        }
    }
}

.calendar {
    min-width: 350px;
    width: 20vw;

    @include screen(tablet) {
        min-width: unset;
        width: 100%;
        padding: 8px 24px 10px 24px;
    }

    &__toolbar {
        display: flex;
        align-items: center;
        align-content: center;
        justify-content: space-between;
        margin: 8px 0;
    }

    &__wrapper {
        display: flex;
        flex-flow: column;
        justify-content: space-between;
        height: calc(100% - 64px);

        &__body {
            display: grid;
            justify-content: center;
    
            &__week-header {
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin-bottom: 8px;
    
                > * {
                    width: 42px;
                    margin: 0 !important;
                    text-align: center;

                    @include screen(374px) {
                        width: 36px;
                    }
                }
            }
    
            &__week {
                display: inline-flex;
                width: 100%;
                margin: 4px 0;
    
                &:nth-of-type(2) {
                    justify-content: flex-end;
                }
    
                &__circle {
                    width: 36px;
                    height: 36px;
                    margin: 0 4px !important;
                    border-radius: 50% !important;
                    background-color: transparent;
        
                    &--checked {
                        background-color: $color-primary !important;
                    }

                    &--disabled {
                        background-color: #f0f0f0 !important;
                    }
        
                    &__text {
                        position: absolute;
                        top: 20%;
        
                        &--checked {
                            color: $color-secondary !important;
                        }

                        &--disabled {
                            color: $color-grey !important;
                        }

                        @include screen(374px) {
                            top: 10%;
                            font-size: .8rem;
                        }
                    }

                    @include screen(374px) {
                        width: 28px;
                        height: 28px;
                    }
                }
            }
        }

        &__actions {
            display: none !important;

            @include screen(desktop) {
                display: flex !important;
            }
        }
    }
}
