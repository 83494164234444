/*!
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

.white-button {
    color: $color-primary !important;
    background-color: #ffffff !important;
}

.MuiButton-root {
    min-width: 0 !important;
    padding: 10px 20px !important;
    font-size: 17px !important;
    text-transform: capitalize !important;
}

.modal-button {
    padding: 5px 30px !important;
    font-size: 12px !important;
    text-transform: uppercase !important;
    background-color: $color-primary !important;
}
