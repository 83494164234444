/*!
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

.profile-dialog {
    .MuiPaper-root {
        display: flex;
        justify-content: center;
        width: 100%;
        border-radius: 15px;

        .MuiDialogContent-root {
            padding: 0;
            padding-bottom: 10px;
        }

        @include screen(mobile) {
            font-size: 12px;
        }

        .wide-form {
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 100%;
            max-width: 800px;
            height: 100%;
            padding: 15px;
            padding-top: 90px;

            &__grid-container {
                display: grid;
                grid-auto-rows: 1fr;
                grid-column-gap: $small-gap;
                grid-template-columns: repeat(2, 1fr);
                grid-row-gap: 10px;
                align-items: center;
                width: 100%;
                margin-bottom: 10px;

                .MuiFormControl-root {
                    justify-content: flex-end;
                }

                &__double-space {
                    grid-column-end: span 2;

                    @include screen(mobile) {
                        width: 100%;
                    }
                }

                &__double-space-mobile {
                    min-height: 65px;
                    @include screen(mobile) {
                        grid-column-end: span 2;
                        width: 100%;
                    }
                }

                &__double-input {
                    display: grid;
                    position: relative;
                    grid-template-columns: auto auto 1fr;
                    grid-template-rows: auto 1fr;
                    width: 100%;
                    margin-bottom: 0;

                    .MuiFormControl-root {
                        margin: 0 !important;

                        &:first-of-type {
                            justify-content: flex-start;
                            .MuiInputBase-root {
                                padding-bottom: 3px;
                                border-top-right-radius: 0 !important;
                                border-bottom-right-radius: 0 !important;

                                .MuiSelect-select {
                                    font-size: 14px;
                                    font-weight: 300;
                                    color: #000000;
                                }
                            }
                        }

                        &:last-of-type {
                            .MuiInputBase-root {
                                border-top-left-radius: 0 !important;
                                border-bottom-left-radius: 0 !important;
                            }
                        }
                    }

                    .Mui-error {
                        padding-bottom: 4px !important;
                    }

                    &__divider {
                        z-index: $menu-z-index;
                        width: 1px;
                        height: 40px;
                        background-color: #ffffff;

                        div {
                            position: relative;
                            top: 50%;
                            height: 28px;
                            background-color: $color-primary;
                            transform: translateY(-50%);
                        }
                    }

                    svg {
                        color: $color-primary;
                    }
                }
            }

            &__picture-container {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                width: 100%;
                text-align: center;

                @include screen(mobile) {
                    flex-direction: column;
                }

                .profile-picture {
                    width: 150px;
                    height: 150px;
                    border-radius: 50%;
                    opacity: 1;
                    transition: opacity 0.3s linear;

                    @include screen(mobile) {
                        width: 100px;
                        height: 100px;
                    }
                }

                p {
                    color: $color-dark-grey;
                }
            }
        }

        .MuiFormControl-root {
            .MuiInputBase-root {
                box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;

                input {
                    font-size: 14px;
                    font-weight: 300;
                    color: #000000;

                    &::placeholder,
                    &::-moz-placeholder,
                    &::-webkit-input-placeholder {
                        opacity: 1;
                    }
                }
            }

            .Mui-disabled {
                input {
                    opacity: 0.5;
                }

                .MuiSelect-root {
                    opacity: 0.5;
                }

                .MuiSelect-nativeInput {
                    display: none;
                }
            }
        }
    }
}

.modal-header {
    display: flex;
    position: absolute;
    z-index: $menu-z-index;
    right: 0;
    left: 0;
    align-items: center;
    padding: 15px;
    font-size: 18px;
    color: $color-primary;
    background-color: $color-secondary;

    svg {
        font-size: 40px;
        cursor: pointer;
    }

    span {
        position: absolute;
        right: 50%;
        transform: translateX(50%);
    }
}
