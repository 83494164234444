/*!
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

@mixin screen($size) {
    $desktop: '(min-width: 1115px)';
    $tablet: '(max-width: 1114px)';
    $mobile: '(max-width: 767px)';

    @if $size == desktop {
        @media only screen and #{$desktop} {
            @content;
        }
    } @else if $size == tablet {
        @media only screen and #{$tablet} {
            @content;
        }
    } @else if $size == mobile {
        @media only screen and #{$mobile} {
            @content;
        }
    } @else {
        @media only screen and (max-width: #{$size}) {
            @content;
        }
    }
}
