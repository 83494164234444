/*!
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

.bookings-screen {
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow: hidden;
    background-color: #ffffff;

    .MuiTabs-root {
        max-width: 400px;
        margin: 0 auto;

        @include screen(tablet) {
            max-width: 100%;
            margin: 0;
        }

        .Mui-selected {
            color: #000000;
        }

        .MuiTabs-indicator {
            background-color: $color-secondary;
        }
    }

    &__list-container {
        display: grid;
        grid-column-gap: $small-gap;
        grid-template-columns: repeat(2, 1fr);
        overflow: auto;

        &::after {
            content: "";
            padding-bottom: 1px;
        }

        @include screen(tablet) {
            display: block;
        }

        &__entry-container {
            margin: $small-gap;
            padding: $small-gap;
            border-radius: $small-gap;
            box-shadow: rgba(99, 99, 99, 0.2) 0 2px 8px 0;

            &--no-reservations {
                display: flex;
                flex-direction: column;
                align-items: center;
                grid-column-end: span 2;
                padding: 40px;

                @include screen(desktop) {
                    margin-left: auto;
                    margin-right: auto;
                }

                &__title {
                    font-size: 20px;
                    font-weight: bold;
                    color: $color-secondary;
                    margin-bottom: 10px;
                }

                &__message {
                    text-align: center;
                }
            }
        }
    }
}

.booking-container {
    display: flex;
    flex-direction: column;
    font-size: 11px;

    a {
        text-decoration: none;
    }

    &--no-booking {
        flex-direction: row;
        margin: 20px 5px;

        &__image {
            margin-right: $small-gap;
        }

        &__content {
            display: flex;
            flex-direction: column;

            &__title {
                margin-bottom: 10px;
                font-size: 22px;
                line-height: 22px;
                color: $color-primary;
            }
        }
    }

    &__alert-box {
        z-index: $above-normal-z-index;

        .MuiDialog-paper {
            display: flex;
            flex-flow: column;
            align-items: center;
            padding: 2rem;
        }

        &__title {
            font-family: $font-titles;
            font-size: 1.4rem;
        }

        &__description {
            font-family: $font-primary;
            font-size: .8rem;
        }

        &__submit {
            background-color: $color-medium-grey !important;
            color: white !important;
            width: 50%;

            &.MuiButton-root {
                padding: .2rem !important;
            }

            @include screen(mobile) {
                width: 70%;
            }
        }
    }

    &__top-container {
        display: flex;
        align-items: center;
        width: 100%;
        min-width: 300px;
        margin-bottom: 10px;


        &--compact {
            min-width: unset;
        }

        button {
            background: none;
            border: none;
        }

        &__business-image {
            max-height: 53px;
            margin-right: $small-gap;
            border-radius: 10px;
        }

        &__text {
            display: flex;
            flex-direction: column;
            margin-right: 20px;
            font-weight: 600;

            &__title {
                font-size: 14px;
                color: $color-primary;
            }

            &__description {
                font-size: 12px;
                color: rgba(#2e2e2e, 0.45);
            }
        }

        &__right-side {
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            margin-left: auto;
            font-size: 12px;
            cursor: pointer;

            &--pending-payment {
                color: $color-red;
                margin-top: 20px;
                margin-bottom: 24px;
                padding: 3px 10px;
                border: 1px solid $color-red;
                border-radius: 15px;
                text-align: center;
                cursor: inherit;
            }

            &--checkout {
                color: $color-red;

                &__square {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 40px;
                    height: 40px;
                    margin-bottom: 10px;
                    background-color: $color-red;
                    border-radius: 6px;

                    img {
                        width: 25px;
                    }
                }

                img {
                    height: 65px;
                }
            }

            &--repeat {
                img {
                    position: relative;
                    bottom: 7px;
                    left: 10px;
                    height: 70px;
                }
            }

            &--checkin {
                color: $color-primary;

                &__image-container {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 50px;
                    height: 50px;
                    background-color: $color-primary;
                    border-radius: 50%;

                    &--square {
                        width: 40px;
                        height: 40px;
                        margin-bottom: 10px;
                        border-radius: 6px;

                        img {
                            width: 20px !important;
                        }
                    }

                    &--not-available {
                        background-color: $color-medium-grey;
                    }
                }

                img {
                    width: 25px;
                }
            }
        }
    }

    &__bottom-container {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;

        &__entire-row {
            margin-top: 10px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: flex-start;
            width: 100%;
            margin-bottom: 10px;

            .MuiFormControl-root {
                .black-text {
                    color: $color-black;
                }
            }

            @include screen(mobile) {
                .MuiFormControl-root {
                    .MuiInputBase-root {
                        textarea {
                            height: 200px !important;
                        }
                    }
                }
            }

            &__label {
                display: flex;
                align-items: center;
                margin-bottom: 5px;
                font-size: 12px;

                svg {
                    color: $color-secondary;
                }

                span {
                    margin-left: 10px;
                    color: $color-primary;
                }
            }
        }

        &__left-container {
            &__element {
                display: flex;
                align-items: center;

                svg {
                    margin-right: 8px;
                    color: $color-secondary;
                }

                &:not(:last-of-type) {
                    margin-bottom: 8px;
                }
            }
        }

        &__right-container {
            display: flex;
            font-size: 14px;
            font-weight: 600;

            > div {
                display: flex;
                gap: 20px;

                @include screen(mobile) {
                    gap: 10px;
                }
            }

            span,
            button {
                padding: 3px 15px;
                color: $color-primary;
                border: 1px solid $color-primary;
                border-radius: 15px;
                background-color: transparent;
                font-weight: 600;
            }

            .canceled {
                color: $color-red;
                border-color: $color-red;
            }

            .transformed {
                padding: 5px 15px;
                font-size: 10px;
                text-transform: uppercase;
            }
        }
    }
}

.details-dialog {
    &__spot-picture {
        flex-shrink: 0;
        width: 100%;
        height: 200px;
        background-position: center center;
        background-size: cover;
    }

    &__store-container {
        width: 100%;

        @include screen(mobile) {
            padding-bottom: 84px;
        }

        &__header {
            display: flex;
            background-color: $color-primary;

            span {
                margin: 12px 24px;
                font-size: 14px;
                font-family: $font-open-sans-light;
                color: white;
            }
        }

        &__payment-details {
            width: 100%;
            padding: 30px;
            display: inline-flex;
            justify-content: center;

            &__inner {
                font-family: $font-open-sans-light;

                h4 {
                    font-family: $font-open-sans-semibold;
                    text-align: center;
                    margin-top: 0;
                    margin-bottom: 8px;
                    font-size: 16px;
                    color: $color-primary;
                }

                > div:not(:last-of-type) {
                    margin-bottom: 4px;
                }
            }
        }

        &__container {
            width: 100%;
            display: grid;
            padding: 0 30px;
            grid-template-columns: 1fr 1fr;
            column-gap: 24px;

            a {
                text-decoration: none;
            }

            &__title {
                display: flex;
                padding: 12px 0;
                color: $color-light-green;
                font-family: $font-open-sans-semibold;
                font-size: 18px;

                @include screen(mobile) {
                    font-size: 14px;
                }
            }

            &__image {
                border-radius: 25px 25px 25px 0;
                width: 100%;
                margin-bottom: 10px;
                box-shadow: 0 3px 6px 0 rgba(black, .15);
            }
        }

        &__no-store-available {
            display: flex;
            flex-flow: column;

            &__title {
                display: flex;
                box-sizing: border-box;
                padding: 36px 63px 0 0;
                color: $color-light-green;
                font-family: $font-open-sans-bold;
                line-height: 27px;
                font-size: 27px;
            }

            &__message {
                padding-top: 10px;
                font-family: $font-open-sans-light;
                font-size: 12px;
            }

            &__image {
                width: 200%;
                margin: 0;

                @include screen(mobile) {
                    width: 100vw;
                    margin: 22px 0 0 -34px;
                }
            }
        }
    }

    &__order-container {
        display: flex;
        position: relative;
        justify-content: center;
        margin-top: $small-gap;

        &__orders {
            display: flex;
            position: relative;
            justify-content: center;
            align-items: center;
            width: 50px;
            height: 50px;
            background-color: $color-secondary;
            border-radius: 50%;
            cursor: pointer;
            transition: width 0.3s linear, height 0.3s linear, padding-top 0.3s linear, border-radius 0.3s linear;

            svg {
                position: absolute;
                top: 10px;
                width: 30px;
                height: 30px;
                transition: top 0.3s linear;

                path {
                    transition: fill 0.3s linear;
                }
            }

            &__element {
                position: absolute;
                top: 60%;
                right: 0.5;
                left: 0.5;
                width: 50px;
                height: 50px;
                background-color: #000000;
                border-radius: 50%;
                opacity: 0;
                transition: top 0.3s linear, left 0.3s linear, right 0.3s linear;

                &--shown {
                    opacity: 1;

                    &:nth-of-type(1) {
                        top: 13px;
                        left: 0.5;
                    }

                    &:nth-of-type(2) {
                        top: 22.5%;
                        left: 17.5%;
                    }

                    &:nth-of-type(3) {
                        top: 22.5%;
                        right: 17.5%;
                    }

                    &:nth-of-type(4) {
                        top: 1;
                        left: 5%;
                    }

                    &:nth-of-type(5) {
                        top: 1;
                        right: 5%;
                    }
                }
            }

            &--expanded {
                width: 100%;
                padding-top: 50%;
                border-radius: 100vw 100vw 25px 25px;

                svg {
                    top: 60%;
                    width: 50px;
                    height: 50px;

                    path {
                        fill: $color-primary !important;
                    }
                }
            }
        }
    }

    &__actions {
        width: 100%;
        height: 84px;
        padding: $small-gap;
        background-color: $color-secondary;
        position: relative;
        bottom: 0;
        justify-content: center !important;

        @include screen(mobile) {
            position: absolute;
            bottom: 0;
        }

        .MuiButton-root {
            display: flex;
            justify-content: space-between;
            padding: 10px 20px !important;
            border-radius: 36px !important;

            img {
                margin-right: 10px;
            }

            span {
                font-family: $font-open-sans-bold !important;
                font-size: 18px;
            }
        }

        &__close-btn.MuiButton-root {
            background-color: $color-primary !important;
            padding: 0 25px 0 $small-gap !important;
        }

        &__checkout-btn {
            background-color: $color-red !important;
        }
    }

    .booking-container {
        margin: $small-gap;

        &__top-container {
            justify-content: space-between;
            align-items: flex-start;
            margin-bottom: 25px;

            &__text {
                &__title {
                    font-size: 22px;
                }

                &__description {
                    margin-top: 5px;
                }
            }

            &__right-container {
                margin-top: 3px;

                span {
                    padding: 3px 15px;
                    color: $color-primary;
                    border: 1px solid $color-primary;
                    border-radius: 15px;
                    font-size: 14px;
                }

                .canceled {
                    color: $color-red;
                    border-color: $color-red;
                }

                .transformed {
                    padding: 5px 15px;
                    font-size: 10px;
                    text-transform: uppercase;
                }
            }
        }

        &__bottom-container {
            flex-direction: column;
            align-items: flex-start;

            &__left-container {
                display: flex;

                &__element {
                    &:first-of-type {
                        margin-right: $small-gap;
                        margin-bottom: 0;
                    }
                }
            }

            &__location {
                margin-bottom: $small-gap !important;
            }
        }
    }
}
