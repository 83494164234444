/*!
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

.landing-modal {

    .fullscreen-modal__header {
        background-color: white;

        .MuiTypography-root {
            display: flex;
            width: 100%;
            justify-content: space-between;
            align-items: center;
            
            img {
                height: 44px;
            }

            button  {
                padding: 15px;

                svg {
                    color: $color-primary;
                }
            }
        }
    }

    &__header-box {
        display: flex;
    }

    &__banner {
        max-height: 250px;
        object-fit: cover;
    }

    &__content {
        padding: 10px 20px;
    }

    &__title {
        color: $color-secondary;
        font-weight: 900;
        font-size: 44px;
        margin: 0;
    }

    &__services {
        color: $color-primary;
        font-size: 14px;
        margin: 0 0 5px 0;
    }

    &__message {
        font-size: 14px;
        margin: 0;
    }

    &__list {
        list-style-type: none;
        padding: 20px 0;

        &-item {
            border-bottom: 1px solid $color-secondary;
            width: 100%;
            padding: 15px 0;
            display: flex;
            align-items: center;
            cursor: pointer;

            svg {
                color: $color-primary;
                font-size: 32px;
            }
        }
    }

    &__tooltip {
        border-radius: 15px;
        width: 67px;
        height: 67px;
    }

    &__item-right {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        margin-left: 15px;
    }

    &__item-info {

        p {
            margin: 0;
        }

        p:first-of-type {
            color: $color-primary;
        }
    }
}