/*!
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

.map-container {
    display: flex;
    position: relative;
    flex-direction: column;
    width: 100%;
    height: 100%;
    background-color: $color-secondary;

    .user-menu {
        position: absolute;
        top: $small-gap;
        right: 30px;
        background-color: #FFFFFF;
        padding: 6px;
        border-radius: 30px;
        display: inline-flex;
        justify-content: space-between;

        @include screen(mobile) {
            right: 20px;
        }

        @include screen(tablet) {
            right: 20px;
        }

        &__user-info {
            display: inline-flex;
            align-items: center;
            text-decoration: none;
            font-family: $font-open-sans-bold;
            font-size: 12px;
            color: #000;

            img, .avatar-placeholder {
                width: 35px;
                height: 35px;
                border-radius: 50%;
                margin-right: 12px;

                @include screen(mobile) {
                    margin-right: 0;
                }

                @include screen(tablet) {
                    margin-right: 0;
                }
            }

            img {
                object-fit: contain;
            }

            .avatar-placeholder {
                background-color: rgba(0, 0, 0, 0.08);
                display: inline-flex;
                justify-content: center;
                align-items: center;
                cursor: pointer;
                border: none;

                svg {
                    width: 15px;
                    height: 15px;
                }
            }

            p {
                margin: 0 10px 0 0;

                @include screen(mobile) {
                    display: none;
                }

                @include screen(tablet) {
                    display: none;
                }
            }
        }
    }

    .MuiFormControl-root {
        position: absolute;
        z-index: $nav-z-index;
        top: $small-gap;
        right: 50%;
        width: 95%;
        max-width: 450px;
        padding: 0 $small-gap;
        transform: translateX(50%);

        @include screen(mobile) {
            left: 5px;
            right: unset;
            width: calc(100% - 70px);
            transform: translateX(0);
        }

        @include screen(tablet) {
            left: 5px;
            right: unset;
            width: calc(100% - 70px);
            transform: translateX(0);
        }

        .MuiInputBase-root {
            background-color: #ffffff;
            border-radius: 30px;
            height: 47px;

            input {
                font-size: 13px;
                color: $color-dark-grey;
            }
        }

        .MuiInputAdornment-positionStart {
            margin-left: 5px;
        }

        svg {
            font-size: 18px;
            color: $color-secondary;
        }
    }
}

.custom-marker-label {
    position: relative;
    bottom: -30px;
    text-shadow: -2px -2px 0 #000000, 2px -2px 0 #000000, -2px 2px 0 #000000, 2px 2px 0 #000000;
}

.special-marker-label {
    position: relative;
    bottom: 6px;
    font-weight: bold;
}

.cluster {
    div {
        top: 50%;
        margin-top: 2px;
        margin-left: 2px;
        transform: translateY(-50%);
    }
}

.booking-modal {
    position: absolute;
    right: 50%;
    bottom: 0;
    padding: $small-gap;
    background-color: #ffffff;
    border-radius: $small-gap $small-gap 0 0;
    transform: translateX(50%);

    @include screen(mobile) {
        width: calc(100% - 30px);
    }
}
