/*!
 *
 * @Copyright 2021 VOID SOFTWARE, S.A.
 *
 */

.reservation-map-counter {
    top: 12%;
    left: 3%;
    position: absolute;
    border-radius: 50px;
    background-color: white;
    padding: 20px 15px;
    z-index: $above-normal-z-index;
    
    div {
        display: flex;
        flex-flow: column;
        align-items: center;

        &:not(:first-child) {
            margin-top: 10px;
        }
    }

    span {
        font-family: $font-primary;
        color: $color-dark-grey;
    }

    &__icon {
        color: $color-secondary;
    }
}
 