/*!
 *
 * @Copyright 2021 VOID SOFTWARE, S.A.
 *
 */

.payment-methods {
    padding: 0 7vw;

    input {
        color: black !important;
    }

    .MuiFormControl-root {
        border-radius: 8px;
        box-shadow: 0 3px 6px 0 rgba(black, .16);

        .MuiInputBase-root {
            margin-top: 4px;
        }
    }

    .field-error {
        padding: 10px;
    }

    &__mbway {
        margin-bottom: 45px;

        > span {
            display: flex;
            padding: 35px 0 12px 0;
            color: $color-dark-grey;
        }

        .MuiIconButton-root,
        .MuiIconButton-label {
            padding: 4px;
        }

        &__icon {
            height: 34px;
        }
    }

    &__nif {
        padding-bottom: 25px;

        span {
            font-family: $font-titles;
            font-size: .75rem;
            color: $color-primary;
            margin-bottom: 18px;
        }

        .MuiFormControl-root {
            margin-top: 18px
        }
    }

    &__credit-card {
        span {
            display: flex;
            padding: 23px 0 10px 0;
            font-family: $font-primary;
            font-size: .75rem;
            color: $color-dark-grey;
        }

        &__half-fields {
            display: flex;
            width: 100%;
            margin-bottom: 9px;
            column-gap: 40px;
        }
    }

    &__method {
        display: flex;
        align-items: center;
        min-height: 75px;
        border-radius: 13px;
        box-shadow: 0 3px 6px 0 rgba(black, .16);
        margin-bottom: 23px;

        &:first-of-type {
            margin-top: 15px;
        }

        &:last-of-type {
            margin-bottom: 30px;
        }

        &__img {
            padding: 0 22px 0 10px;
            height: 60px;

            &--custom-image {
                padding: 0;
                margin: 0 34px 0 26px;
                width: 32px;
            }
        }

        span {
            font-family: $font-open-sans-semibold;
            font-size: .75rem;
        }
    }
}
