/*!
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

// colors
$color-primary: #3ea08e;
$color-secondary: #f7cf78;
$color-light-grey: #f0f0f0;
$color-dark-grey: #777777;
$color-red: #e87373;
$color-red-FF0909: #FF0909;
$color-grey: #999999;
$color-light-green: #3db298;
$color-medium-grey: #9a9a9a;
$color-error: #ff4e00;
$color-success: #51dfa2;
$color-error-bg: #ffe0e0;
$color-blue: #4c9aff;
$color-black: #000000;

// fonts
$font-titles: 'PaytoneOne-Regular', sans-serif;
$font-primary: 'OpenSansRegular', sans-serif;
$font-open-sans-light: 'OpenSansLight', sans-serif;
$font-open-sans-bold: 'OpenSansBold', sans-serif;
$font-open-sans-semibold: 'OpenSansSemibold', sans-serif;

// sizes
$small-gap: 15px;
$medium-gap: 50px;
$large-gap: 100px;
$nav-height: 60px;

// z-indexes
$above-normal-z-index: 2;
$nav-z-index: 3;
$menu-z-index: 4;
$drawer-z-index: 5;
$backdrop-z-index: 7;
