/*!
 *
 * @Copyright 2021 VOID SOFTWARE, S.A.
 *
 */

.reservation-group {    
    .MuiPaper-root{
        width: 100%;
        max-height: 70vh;

    }

    &__booking-entry {
        margin: 15px 20px;
        padding: 15px;
        background: none;
        border: none;
        box-shadow: rgba(99, 99, 99, 0.2) 0 2px 8px 0;
        border-radius: 15px;
    }
}
