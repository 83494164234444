/*!
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

.header-container {
    display: flex;
    position: relative;
    align-items: center;
    width: 100%;
    margin-bottom: $medium-gap;

    &__header {
        position: absolute;
        left: 50%;
        font-weight: normal;
        transform: translate(-50%);
        @include screen(mobile) {
            text-align: center;
        }
    }
}
