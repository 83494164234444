/*!
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

* {
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
}

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
        'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    overflow: hidden;
}

.options-icon {
    cursor: pointer;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    -webkit-text-fill-color: white !important;
    transition: background-color 9999s ease-in-out 0s;
}


// ERRORS

.field-error {
    font-size: 11px;
    line-height: 11px;
    color: #f44336;
}

.auth-screen {
    .field-error {
        color: #ffffff;
    }
}

// LINKS

.return-link {
    display: flex;
    align-items: center;
    align-self: flex-start;
    text-decoration: none;
    color: #000000;
}

// ALIGNMENT

.align-start {
    align-self: flex-start;
}

// show/hide

.mobile-hidden {
    @include screen(mobile) {
        display: none;
    }
}

// clickable

.clickable {
    cursor: pointer;
}

// text

.bold {
    font-weight: 750;
}

.loading {
    z-index: $backdrop-z-index !important;
}
