/*!
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

.drawer {
    display: flex;
    z-index: $drawer-z-index;
    flex: 1 0 auto;
    flex-direction: column;
    padding: 30px;
    overflow-x: initial;
    overflow-y: auto;
    background-color: #ffffff;
    border-right: 1px solid #000000;
    transition: left 0.3s linear;
    height: 100%;

    @include screen(mobile) {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 100%;
        padding: 0;
        border: none;
        box-shadow: rgba(0, 0, 0, 0.35) 0px 5px $small-gap;
    }

    .drawer__close-icon {
        display: none;
        color: $color-primary;
        cursor: pointer;

        @include screen(mobile) {
            display: block;
        }
    }

    .user-info {
        display: flex;
        align-items: center;
        margin: $small-gap 0;

        &__text-container {
            display: flex;
            flex-direction: column;

            span {
                &:first-of-type {
                    color: $color-primary;
                }

                &:not(:first-of-type) {
                    font-size: 13px;
                }
            }
        }

        img {
            width: 45px;
            height: 45px;
            margin-right: $small-gap;
            border-radius: 50%;
            box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 20px, rgba(0, 0, 0, 0.15) 0px 6px 6px;
        }
    }

    &--active {
        color: $color-primary !important;
    }

    &__top-container {
        display: flex;
        flex-direction: column;
        margin-bottom: $medium-gap;
    }

    &__middle-container {
        min-width: 230px;
        
        a,
        button {
            display: flex;
            align-items: center;
            margin: $small-gap 0;
            padding: 0;
            font-size: 22px;
            text-decoration: none;
            white-space: nowrap;
            color: $color-primary;
            background-color: transparent;
            border: none;
            cursor: pointer;
        }
    }

    &__bottom-container {
        display: flex;
        grid-gap: $small-gap;
        flex-direction: column;
        align-items: flex-start;
        margin-top: auto;
        font-size: 14px;

        button,
        a {
            padding: 0;
            color: #000000;
            background-color: transparent;
            border: none;
            cursor: pointer;
        }

        a {
            text-decoration: none;
        }

        img {
            height: 25px;
        }

        span {
            font-size: 12px;
        }
    }

    svg {
        margin-right: 10px;
    }

    &__mobile-toggle {
        display: none !important;
        position: fixed;
        z-index: $menu-z-index;
        top: $small-gap;
        right: $small-gap;
        font-size: 28px !important;
        color: #ffffff;
        cursor: pointer;

        @include screen(mobile) {
            display: block !important;
        }
    }

    &--expanded {
        padding: $small-gap;
        @include screen(mobile) {
            left: $small-gap;
        }

        .drawer__mobile-toggle {
            position: relative;
            top: 0;
            left: 0;
            margin-bottom: $small-gap;
        }
    }
}