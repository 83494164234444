/**
 *
 * @Copyright 2022 VOID SOFTWARE, S.A.
 *
 */

.dialog {
    &__header {
        width: 100%;
        display: inline-flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 20px;

        h3 {
            margin: 0;
        }
    }

    &__inner {
        text-align: center;

        p {
            font-size: 16px;
            line-height: 1.4;
            margin: 0;
        }
    }

    &__buttons {
        width: 100%;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        margin-top: 20px;

        .modal-button {
            &.Mui-disabled {
                background-color: rgba($color-primary, 0.7);
            }

            .MuiCircularProgress-root {
                width: 15px !important;
                height: 15px !important;
                margin-left: 10px;

                &.MuiCircularProgress-colorPrimary {
                    color: $color-secondary;
                }
            }
        }
    }
}

.accept-invite-modal {
    padding: 20px;
    max-width: 400px;
    min-width: 260px;
    background-color: #FFF;

    a, a:visited {
        font-size: 16px;
        line-height: 1.4;
        margin: 0;
        color: $color-primary;
    }

    &__error-msg {
        color: $color-red-FF0909;
    }
}
