/*!
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

.schedule-table {
    display: grid;
    grid-template-columns: auto 1fr;
    grid-auto-rows: 1fr;
    width: 100%;

    &__date {
        padding: 10px;
        border: 1px solid rgba(224, 224, 224, 1);
    }

    &__hours-container {
        display: flex;

        span {
            display: flex;
            flex: 1;
            align-items: center;
            padding-left: 2px;
            border: 1px solid rgba(224, 224, 224, 1);
        }
    }

    &__bollard-container {
        padding: 10px;
        border: 1px solid rgba(224, 224, 224, 1);

        &__schedule-container {
            display: flex;

            &__schedule {
                border: 1px solid rgba(224, 224, 224, 1);

                &--unavailable {
                    background-color: $color-light-grey;
                }

                &--available {
                    cursor: pointer;
                    &:hover {
                        background-color: rgba(#00ff00, 0.5);
                    }
                }

                &--reserved {
                    background-color: rgba(#ff0000, 0.5);
                }

                &--selected {
                    background-color: rgba(#00ff00, 0.5);
                }
            }
        }
    }
}
